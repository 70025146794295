import React, { useState } from "react";
import Modal from "react-modal";
import Brand from "./Brand";

Modal.setAppElement("#root");

const About = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="tokyo_tm_about">
        <div className="about_image ">
          <img src="assets/img/slider/1.jpg" alt="about" />
        </div>
        {/* <div className="o-video">
          <iframe src="https://www.youtube.com/embed/0yW7w8F2TVA"></iframe>
        </div> */}
        {/* END ABOUT IMAGE */}
        <div className="description">
          <h3 className="name">Billie Tran | Full Stack Web Developer</h3>
          <div className="description_inner">
            <div className="left">
              <p>
                Hello, I am a full stack web developer based in the Bay Area, California. I have a passion for learning and developing programs and websites for personal and business uses. 
              </p>
              <p>
                I am interested in working with others who share the same passion for technology that I do. 
              </p>
              <div className="tokyo_tm_button">
                <button onClick={toggleModal} className="ib-button">
                  Read More
                </button>
              </div>
              {/* END TOKYO BUTTON */}
            </div>
            {/* END LEFT */}
            <div className="right">
              <ul>
                <li>
                  <p>
                    <span>Location:</span>
                    <p>Bay Area, California</p>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Phone:</span>
                    <p><a href="tel:+14084718082">+1(408)471-8082</a></p>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Email:</span>
                    <p><a href="mailto:billietran.developer@gmail.com">billietran.developer@gmail.com</a></p>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Study:</span>
                    <p>Western Governors University(BSCS)</p>
                  </p>
                </li>
              </ul>
              {/* END UL */}
            </div>
            {/* END RIGHT */}
          </div>
          {/* END DESCRIPTION INNER */}
        </div>
      </div>

      {/* START ABOUT POPUP BOX */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_about">
          <button className="close-modal" onClick={toggleModal}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END POPUP CLOSE BUTTON */}
          <div className="box-inner">
            <div
              className="description_wrap scrollable"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="my_box">
                <div className="left">
                  <div className="about_title">
                    <h3>Web Development Skills</h3>
                  </div>
                  {/* END ABOUT TITLE */}

                  <div className="tokyo_progress">
                    <div className="progress_inner">
                      <span>
                        <span className="label">HTML</span>
                      </span>
                    </div>

                    <div className="progress_inner">
                      <span>
                        <span className="label">CSS</span>
                      </span>
                    </div>

                    <div className="progress_inner">
                      <span>
                        <span className="label">React</span>
                      </span>
                    </div>

                    <div className="progress_inner">
                      <span>
                        <span className="label">Node</span>
                      </span>
                    </div>

                    <div className="progress_inner">
                      <span>
                        <span className="label">Express</span>
                      </span>
                    </div>
                  </div>
                  {/* END PROGRESS */}
                </div>
                {/* END LEFT */}

                <div className="right">
                  <div className="about_title">
                    <h3>Language Skills</h3>
                  </div>
                  {/* END TITLE */}
                  <div className="tokyo_progress">
                    <div className="progress_inner" data-value="95">
                      <span>
                        <span className="label">Javascript</span>
                      </span>
                    </div>

                  </div>
                  {/* EDN TOKYO PROGRESS */}
                </div>
                {/* END RIGHT */}
              </div>
              {/* END MYBOX */}
            {/*
              <div className="counter">
                <div className="about_title">
                  <h3>Fun Facts</h3>
                </div>
                <ul>
                  <li>
                    <div className="list_inner">
                      <h3>777+</h3>
                      <span>Projects Completed</span>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3>3K</h3>
                      <span>Happy Clients</span>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3>9K+</h3>
                      <span>Lines of Code</span>
                    </div>
                  </li>
                </ul>
                END COUNTER CONTENT 
              </div>
            
              END COUNTER

              <div className="partners">
                <div className="about_title">
                  <h3>Our Partners</h3>
                </div>
                <Brand />
              </div>
               END PARTNER SLIDER 
            */}
            </div>
          </div>
        </div>
      </Modal>
      {/* END ABOUT POPUP BOX */}
    </>
  );
};

export default About;
