import React from "react";
import Social from "./Social";

const Home = () => {
  return (
    <>
      <div className="tokyo_tm_home">
        <div className="home_content">
          <div className="avatar">
            <div
              className="image avatar_img"
              style={{
                backgroundImage: "url(assets/img/slider/1.jpg)",
              }}
            ></div>
            {/* END AVATAR IMAGE */}
          </div>
          {/* END AVATAR */}
          <div className="details">
            <h3 className="name">Hi, I'm Billie</h3>
            <p className="job">
              I am a full stack web developer interested in front end and back end development from the Bay Area, California.
            </p>
            <h4 className="quick-margin">
              Let's work together:
            </h4>
            <p>
              <span className="quick-text">Phone:</span>
              <a className="quick-hover" href="tel:+14084718082"> +1(408)471-8082</a>
            </p>
            <p>
              <span className="quick-text">Email:</span>
              <a className="quick-hover" href="mailto:billietran.developer@gmail.com"> billietran.developer@gmail.com</a>
            </p>
            
            
            {/* END JOB */}
            <Social />
          </div>
          {/* END DETAILS */}
        </div>
        {/* END HOME CONTENT */}
      </div>
      {/* END HOME */}
    </>
  );
};

export default Home;
